<template>
  <l-feature-group>
    <template>
      <l-polygon
        v-if="sensor.show_sectors"
        :ref="`discovairds-${candidate_identifier}`"
        :visible="visible && !isOutside"
        color="#FF9800"
        fill-color="#FF9800"
        :fill-opacity="fillOpacity"
        :weight="weight"
        @click="onSelect"
        :lat-lngs="sectorPolygon(false)"
      />
      <l-polygon
        v-if="sensor.show_sector_as_line"
        :ref="`discovairdl-${candidate_identifier}`"
        :visible="visible && !isOutside"
        color="#FF9800"
        fill-color="#FF9800"
        :fill-opacity="fillOpacity"
        :weight="weight + 1"
        @click="onSelect"
        :lat-lngs="sectorPolygon(true)"
      />
      <template v-if="isOutside && sensor.show_outside_detections">
        <l-polygon
          :visible="visible && withinExtendedRange"
          color="#FF9800"
          :weight="1"
          :opacity="0.5"
          :fill-opacity="0"
          :lat-lngs="range"
        />
        <l-polygon
          v-for="(a, i) in [1, 2, 4, 6, 8, 10]"
          :key="`polygon-outside-${i}`"
          :visible="visible && withinExtendedRange"
          :className="`discovairOutsideDetection${a > 1 ? ` faded-${i}` : ''}`"
          color="#FF9800"
          fill-color="#FF9800"
          :fill-opacity="fillOpacity"
          :weight="0"
          @click="onSelect"
          :lat-lngs="outsideDetection(a)"
        />
      </template>
    </template>
  </l-feature-group>
</template>

<script>
import utils from '../utils'
import { mapGetters } from 'vuex'
import { LFeatureGroup, LPolygon } from 'vue2-leaflet'

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  azimuth: {
    type: [String, Number],
    default: 0
  },
  candidate_identifier: {
    type: [String, Number]
  },
  discovair_sensor_id: {
    type: [String, Number]
  },
  activeBeam: {
    type: [String, Number]
  },
  activeSensorId: {
    type: Number
  }
}

export default {
  name: 'DiscovairDetectionSector',
  props,
  data: () => ({
    watcher: null
  }),
  components: {
    LFeatureGroup,
    LPolygon
  },
  computed: {
    ...mapGetters('sensors', [
      'discovair_sensorById',
      'discovair_sensorsInSentry',
    ]),
    ...mapGetters('cameras', ['allCameras']),
    ...mapGetters('sentries', ['sentriesSet']),
    key() {
      return `${this.candidate_identifier}:${this.discovair_sensor_id}`
    },
    isSelected() {
      return this.activeBeam === this.key
    },
    fillOpacity() {
      return this.isSelected ? 0.4 : 0.2
    },
    weight() {
      return this.isSelected ? 2 : 1
    },
    sensor() {
      return this.discovair_sensorById(this.discovair_sensor_id) || {}
    },
    sectorPolygon() {
      return (isLine) => {
        const { latitude: lat, longitude: lng, reach } = this.sensor
        return lat
          ? utils.makeArcPathFromDiscovairDetection({
              azimuth: this.azimuth,
              vertex: { lat, lng },
              reach,
              show_sector_as_line: isLine
            })
          : []
      }
    },
    range() {
      const { reach, direction = 0, angle = 90 } = this.sensor || {}
      return reach
        ? utils.createRange({
            reach,
            direction,
            angle,
            vertex: [this.sensor.latitude, this.sensor.longitude]
          })
        : []
    },
    angleDifference() {
      const { direction: s_deg } = this.sensor
      // const d_deg = (this.azimuth * 180) / Math.PI
      const d_deg = this.azimuth
      return ((d_deg - s_deg + 540) % 360) - 180
    },
    withinExtendedRange() {
      return (
        Math.abs(this.angleDifference) < 70 &&
        !this.anyRfSensorOverlap(this.positiveAngle ? 'right' : 'left')
      )
    },
    positiveAngle() {
      return Math.sign(this.angleDifference) > 0
    },
    isOutside() {
      return this.angleDifference >= 45 || this.angleDifference <= -45
    },
    outsideDetection() {
      return angle => {
        const [min, max] = this.positiveAngle
          ? [45, 45 + angle]
          : [-45 - angle, -45]
        const { latitude, longitude, reach, direction } = this.sensor
        return utils.createRange({
          reach,
          direction,
          min,
          max,
          vertex: [latitude, longitude]
        })
      }
    },
    otherRfSensors() {
      return this.discovair_sensorsInSentry(
        this.sentriesSet[this.sensor.sentry_id]
      ).filter(sensor => sensor.id != this.sensor.id)
    },
    cameraRfTracked() {
      return (
        this.allCameras.length &&
        this.allCameras[0].rf_tracked_target === this.correlation_key
      )
    }
  },
  methods: {
    onSelect() {
      this.$emit('selectBeam', this.key)
    },
    // TODO: Handle this on the backend, maybe add a sensor attribute ignoreOutsideLeft...
    anyRfSensorOverlap(side = 'left') {
      return this.otherRfSensors.some(rfsensor => {
        const otherDirection = utils.circularBounds(rfsensor.direction, [
          0,
          360
        ])
        const sensorNeighBourDirection = utils.circularBounds(
          this.sensor.direction + (side == 'left' ? -90 : 90),
          [0, 360]
        )
        return sensorNeighBourDirection == otherDirection
      })
    },
    blinkRfDetection(action = true) {
      const detectionRef = [
        this.$refs[`rfds-${this.candidate_identifier}`],
        this.$refs[`rfdl-${this.candidate_identifier}`]
      ]
      detectionRef
        .map(dref => dref && dref.mapObject._path)
        .filter(p => p)
        .forEach(path => {
          action
            ? path.classList.add('blinkMap')
            : path.classList.remove('blinkMap')
        })
    }
  },
  mounted() {
    this.$nextTick(() => {
      const xmlns = 'http://www.w3.org/2000/svg'
      const svg = document.querySelector(
        'div.leaflet-pane.leaflet-overlay-pane > svg'
      )
      if (svg && !svg.getElementsByTagName('defs').length) {
        const defs = document.createElementNS(xmlns, 'defs')
        // rfZeroDetection
        const radialGradient = document.createElementNS(xmlns, 'radialGradient')
        radialGradient.setAttributeNS(null, 'id', 'rfZeroDetection')
        const stop1 = document.createElementNS(xmlns, 'stop')
        stop1.setAttributeNS(null, 'offset', 0.176414)
        stop1.setAttributeNS(
          null,
          'style',
          'stop-color: #FF9800; stop-opacity: 1'
        )
        const animate1 = document.createElementNS(xmlns, 'animate')
        animate1.setAttributeNS(null, 'attributeName', 'offset')
        animate1.setAttributeNS(null, 'dur', '1500ms')
        animate1.setAttributeNS(null, 'values', '0;.8;0')
        animate1.setAttributeNS(null, 'repeatCount', 'indefinite')
        animate1.setAttributeNS(
          null,
          'keySplines',
          '.77 .25 .82 .53; .15 .56 .47 .83'
        )
        stop1.appendChild(animate1)
        const stop2 = document.createElementNS(xmlns, 'stop')
        stop2.setAttributeNS(null, 'offset', '100%')
        stop2.setAttributeNS(
          null,
          'style',
          'stop-color: #FF9800; stop-opacity: 0'
        )
        radialGradient.appendChild(stop1)
        radialGradient.appendChild(stop2)
        defs.appendChild(radialGradient)

        const filter1 = document.createElementNS(xmlns, 'filter')
        filter1.setAttributeNS(null, 'id', `blurred1`)
        const feGaussianBlur1 = document.createElementNS(
          xmlns,
          'feGaussianBlur'
        )
        feGaussianBlur1.setAttributeNS(null, 'stdDeviation', 2)
        filter1.appendChild(feGaussianBlur1)
        const filter2 = document.createElementNS(xmlns, 'filter')
        filter2.setAttributeNS(null, 'id', `blurred2`)
        const feGaussianBlur2 = document.createElementNS(
          xmlns,
          'feGaussianBlur'
        )
        feGaussianBlur2.setAttributeNS(null, 'stdDeviation', 3)
        filter2.appendChild(feGaussianBlur2)
        const filter3 = document.createElementNS(xmlns, 'filter')
        filter3.setAttributeNS(null, 'id', `blurred3`)
        const feGaussianBlur3 = document.createElementNS(
          xmlns,
          'feGaussianBlur'
        )
        feGaussianBlur3.setAttributeNS(null, 'stdDeviation', 5)
        filter3.appendChild(feGaussianBlur3)
        const filter4 = document.createElementNS(xmlns, 'filter')
        filter4.setAttributeNS(null, 'id', `blurred4`)
        const feGaussianBlur4 = document.createElementNS(
          xmlns,
          'feGaussianBlur'
        )
        feGaussianBlur4.setAttributeNS(null, 'stdDeviation', 10)
        filter4.appendChild(feGaussianBlur4)
        const filter5 = document.createElementNS(xmlns, 'filter')
        filter5.setAttributeNS(null, 'id', `blurred5`)
        const feGaussianBlur5 = document.createElementNS(
          xmlns,
          'feGaussianBlur'
        )
        feGaussianBlur5.setAttributeNS(null, 'stdDeviation', 15)
        filter5.appendChild(feGaussianBlur5)
        defs.append(filter1)
        defs.append(filter2)
        defs.append(filter3)
        defs.append(filter4)
        defs.append(filter5)

        const linearGradient = document.createElementNS(xmlns, 'linearGradient')
        linearGradient.setAttributeNS(null, 'id', 'rfOutsideDetection')

        const stop3 = document.createElementNS(xmlns, 'stop')
        stop3.setAttributeNS(null, 'offset', '0%')
        stop3.setAttributeNS(
          null,
          'style',
          'stop-color: #FF9800; stop-opacity: .5'
        )
        const animate2 = document.createElementNS(xmlns, 'animate')
        animate2.setAttributeNS(null, 'attributeName', 'stop-opacity')
        animate2.setAttributeNS(null, 'dur', '1500ms')
        animate2.setAttributeNS(null, 'values', '0.2;.6;0.2')
        animate2.setAttributeNS(null, 'repeatCount', 'indefinite')
        animate2.setAttributeNS(
          null,
          'keySplines',
          '.77 .25 .82 .53; .15 .56 .47 .83'
        )
        stop3.appendChild(animate2)
        linearGradient.appendChild(stop3)
        defs.append(linearGradient)

        svg.appendChild(defs)
      }
      if (this.cameraRfTracked) this.blinkRfDetection()
    })
  },
  watch: {
    cameraRfTracked(isTracking) {
      this.blinkRfDetection(isTracking)
    }
  }
}
</script>

<style>
.rfZeroDetection {
  fill-opacity: 0.4 !important;
  fill: url(#rfZeroDetection) !important;
}
.discovairOutsideDetection {
  fill-opacity: 0.2 !important;
  fill: url(#rfOutsideDetection) !important;
}

.faded-1 {
  filter: url(#blurred1);
}
.faded-2 {
  filter: url(#blurred2);
}
.faded-3 {
  filter: url(#blurred3);
}
.faded-4 {
  filter: url(#blurred4);
}
.faded-5 {
  filter: url(#blurred5);
}

</style>
